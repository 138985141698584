<template>
  <div class="editArticleAll">
    <div class="editArticle">
      <van-form class="editCenter" @submit="onSubmit" label-width="160px" label-align="left">
        <van-field name="radio" label="需求类别">
          <template #input>
            <van-radio-group v-model="form.demandType">
              <van-radio v-for="item in demandList" :name="item.label" :key="item.label" class="radioBox">{{ item.value
              }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 需求类别 -->
        <!-- <van-field @focus="noBomBox" name="demandType" v-model="form.demandType_text" label="需求类别" required
        placeholder="请选择需求类别" @click="showPicker = true" :rules="[{ required: true }]" right-icon="arrow">
      </van-field> -->
        <!-- 选需求类别谈弹出框 -->
        <!-- <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar label="label" value-key="value" :columns="demandList" @confirm="onConfirm"
          @cancel="showPicker = false" />
      </van-popup> -->
        <van-field name="title" v-model="form.title" size="20px" label="标题" placeholder="请填写标题"></van-field>
        <van-field name="compName" v-model="form.compName" label="公司名" placeholder="请填写公司名"></van-field>
        <!-- <van-field name="center" label="需求内容" required>
        <template #input>
          <quill-editor class="edit" v-model="form.demandContent" ref="myQuillEditor" :options="editorOption"
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)">
          </quill-editor>
        </template>
      </van-field> -->
        <van-field name="demandContent" type="textarea" rows="5" v-model="form.demandContent" label="需求内容"
          placeholder="请填写需求内容" required :rules="[{ required: true }]"></van-field>
        <van-field name="mobile" v-model="form.mobile" type="tel" label="电话" required maxlength="11" placeholder="请填写电话"
          :rules="telRules">
        </van-field>
        <div class="toOption">
          <van-button round block native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { get_repairtype_list } from "@/api/repair"
import Cookies from 'js-cookie'
import { getParamQueryString } from '@/util/getUrlData'
import { add_comp_demand, edit_comp_demand, get_cloud_demand } from '@/api/editArticle'
export default {
  name: 'editArticle',
  data() {
    return {
      title: '新增',
      showPicker: false,
      demandList: [],
      editorOption: {},
      form: {
        title: '', // 标题
        compName: '', // 商户名
        mobile: '', // 电话
        demandContent: '',
        demandType: '',// 需求类别
        // demandType_text: '',
        parkId: '', // 园区
        status: '0', // 审核状态
      },
      // 校验手机号码
      telRules: [{
        required: true,
        message: '手机号码不能为空',
        trigger: 'onChange'
      }, {
        // 自定义校验规则
        validator: (value) => {
          return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
            .test(value)
        },
        message: '请填写正确格式的手机号码',
        trigger: 'onChange'
      }],
    }
  },
  created() {
  },
  mounted() {
    // 获取需求类别
    this.getDemandType();
    this.title = getParamQueryString(window.location.href, 'id') ? '编辑' : '新增'
    this.form.parkId = Cookies.get('parkId');// 默认值
    this.cloudDetail();
  },
  methods: {
    // 获取需求类别
    getDemandType() {
      // 获取访客状态：
      return get_repairtype_list({ dictId: '1581296697578299393' }).then((res) => {
        let demandList = res.data.result?.records.map((e) => {
          return {
            value: e.itemText,
            label: Number(e.itemValue),
          }
        });
        this.demandList = demandList
        return { demandList }
      })
    },
    // 获取企业需求文章
    async cloudDetail() {
      const { demandList } = await this.getDemandType();
      let id = getParamQueryString(window.location.href, 'id')
      let demandTypeId = Number(getParamQueryString(window.location.href, 'demandTypeId'))
      if (id) {
        await get_cloud_demand({ id }).then((res) => {
          this.form = res.data.result
          const oldtype = demandList.find((i) => { return i.label === res.data.result?.demandType })
          this.onConfirm(oldtype)
        })
      } else {
        this.form.mobile = Cookies.get('repairMobile')
        let newtype = demandList.find((i) => {
          return i.label === demandTypeId
        })
        this.onConfirm(newtype)
      }
    },
    // 需求类别选择：
    onConfirm(val) {
      // this.form.demandType_text = val.value
      this.form.demandType = val.label
      this.showPicker = false;
    },
    // 禁止调起键盘
    noBomBox() {
      document.activeElement.blur();
    },
    // onEditorBlur() {
    //   console.log('blur', this.messages)
    // },

    // onEditorFocus() {
    //   console.log('focus', this.messages)
    // },

    // onEditorReady() {
    //   console.log('ready', this.messages)
    // },
    // 提交表单：
    onSubmit() {
      let id = getParamQueryString(window.location.href, 'id')
      // let id = '1642140000460398593'
      if (id) {
        edit_comp_demand(this.form).then((res) => {
          this.$toast.success(res.data.message)
        })
      } else {
        add_comp_demand(this.form).then((res) => {
          this.$toast.success(res.data.message)
          this.form.title=''
          this.form.compName=''
          this.form.demandContent = '' // 避免重复提交
          // this.clear();
        })
      }
    },
    clear() {
      this.form = {
        title: '', // 标题
        compName: '', // 商户名
        //mobile: '', // 电话
        demandContent: '',
        //demandType: '',// 需求类别
        // demandType_text: '',
        parkId: '', // 园区
        status: '0', // 审核状态
      }
    },
  },
}
</script>
<style scoped>
.editArticleAll{
  background-color: #fff;
  min-height: 100vh;
  border: 1px solid #FFF;
}
.editArticle {
  background-color: #FFF;
  /* padding-bottom: 70px; */
  /* height: 100vh; */
  margin-bottom: 60px;
}

.title {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  /* height: 50px; */
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  background-color: #FFF;
  z-index: 2;
}

.editCenter {
  /* margin-top: 70px; */
}

:deep .van-sidebar-item--select::before {
  background-color: #5399FF;
}

:deep .van-field__control {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

:deep .van-field__label {
  margin-bottom: 12px;
}

:deep .van-cell {
  /* margin-bottom: 10px; */
}

::v-deep(.van-cell) {
  display: block;
}

:deep .ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 180px;
}

.edit {
  background: #FFF;
  box-sizing: border-box;
}

.radioBox {
  margin-bottom: 10px;
}
</style>