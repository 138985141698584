<template>
  <div class="cloudDetail">
    <!-- 供应： -->
    <div v-if="showBox === '1'">
      <div class="cloudTitleBox">
        <div class="cloudTitle-left">
          <img :src="imgurl">
        </div>
        <div class="cloudTitle-right">
          <h4 class="fs-25">{{ detail.title }}</h4>
          <p class="grayTColor">商户名：{{ detail.compName }}</p>
          <p class="grayTColor">优惠：<span class="mainTcolor fs-25">{{ detail.price }}</span></p>
        </div>
      </div>
      <div class="centerBox">
        <h4 class="fs-20 tl mt-10">服务介绍：</h4>
        <div class="introduction" style="white-space:pre-line !important" v-html="detail.servDesc"></div>
      </div>
      <div class="centerBox">
        <h4 class="fs-20 tl mt-10">温馨提示：</h4>
        <div class="introduction" v-html="detail.reminderMsg"></div>
      </div>
      <div class="centerBox">
        <h4 class="fs-20 tl mt-10">适用条件：</h4>
        <div class="introduction" v-html="detail.servCondition"></div>
      </div>
      <div class="mb-60"></div>
      <!-- 提交： -->
      <div class="toOption">
        <van-button @click="submit(0)">拨打电话</van-button>
      </div>
    </div>
    <div v-if="showBox === '2'">
      <div class="cloudTitleBox">
        <div class="cloudTitle-right">
          <h4 class="fs-25">{{ detail.title }}</h4>
          <p class="grayTColor">商户名：{{ detail.compName }}</p>
        </div>
      </div>
      <div class="centerBox">
        <h4 class="fs-20 tl mt-10">需求内容：</h4>
        <div class="introduction" v-html="detail.demandContent"></div>
      </div>
      <div class="mb-60"></div>
      <!-- 提交： -->
      <div class="toOption">
        <van-button @click="submit(1)">拨打电话</van-button>
      </div>
    </div>
    <van-empty class="van-empty" v-if="showBox == '0'" description="暂无信息" />
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import baseURL from "../../util/config"
import { getParamQueryString } from '@/util/getUrlData'
import { get_cloud_supply, get_cloud_demand } from "@/api/cloud"
import { add_phonecall } from "@/api/repair"

export default {
  name: 'cloudDetail',
  data() {
    return {
      showBox: '0',
      imgurl: '',
      detail: {
        title: '',
        compName: '',
        price: '',
        servDesc: '', // 服务介绍
        // subClass: '',
        supType: '',
      },
      servMobile: '', //拨打电话
    }
  },
  activated: function () {
    this.cloudDetail();
    document.title = '云商详情'
  },
  methods: {
    cloudDetail() {
      let dictId = getParamQueryString(window.location.href, 'dictId')
      let id = getParamQueryString(window.location.href, 'id')
      // let dictId = '1581296562366521346'
      // let dictId = '1581296697578299393'

      // let id = '1631862544130306049'
      // let id = '1625317695688204289'
      // let id = '1642140000460398593'

      if (dictId === '1581296562366521346' && id) {
        get_cloud_supply({ id }).then((res) => {
          this.showBox = "1"
          if (res.data.code === 200) {
            this.imgurl = baseURL + res.data.result.titlePic
            this.servMobile = res.data.result.servMobile
            this.detail = res.data.result
          } else {
            this.showBox = '0'
            this.$toast(res.data.message)
          }
        })
      } else if (dictId === '1581296697578299393' && id) {
        get_cloud_demand({ id }).then((res) => {
          this.showBox = '2'
          if (res.data.code === 200) {
            this.imgurl = baseURL + res.data.result.titlePic
            this.servMobile = res.data.result.mobile
            this.detail = res.data.result
          } else {
            this.showBox = '0'
            this.$toast(res.data.message)
          }
        })
      }
      else {
        console.log('不满足请求条件')
        this.detail = {}
        this.imgurl = ''
        this.servMobile = ''
        this.showBox = '0'
      }
    },
    // 拨打电话
    submit(mainClass) {
      // mainClass供应-0,求-1
      const addCall = {
        infoId: this.detail.id,
        infoTitle: this.detail.title,
        mainClass: mainClass,
        subClass: this.detail.supType,
        parkId: Cookies.get('parkId'),
        phone: this.servMobile,
      }
      console.log(addCall, 'addCall');
      add_phonecall(JSON.parse(JSON.stringify(addCall))).then()
      if (this.servMobile) {
        // console.log('this.servMobile', this.servMobile)
        window.location.href = 'tel:' + this.servMobile
      } else {
        this.$toast('暂无联系电话')
      }
    }
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* 云商详情 */
.cloudDetail {
  width: 100vw;
  padding: 0 !important;
  min-height: 100vh;
  box-sizing: border-box !important;
  word-wrap: break-word !important;
  background-color: #ffffff;
  display: flex;
  white-space: normal;
}

.cloudTitleBox {
  display: flex;
  padding: 10px;
  flex: nowrap;
}

.cloudTitle-left {
  width: 30%; /*150px*/
}

.cloudTitle-left img {
  width: 90%;
}

.cloudTitle-right {
  padding-left: 10px;
  text-align: left;
}

.centerBox {
  text-indent: 10px;
  box-sizing: border-box;
}

.introduction {
  box-sizing: border-box;
  text-align: left;
  /* width: 100%; */
  width: 100vw;
  word-wrap: break-word !important;
  word-break: break-all !important;
  white-space: normal;
  overflow: scroll;
  padding: 0 !important;
  overflow: hidden;
}
.introduction p {
  width: 100vw;
  position: relative;
}
.introduction img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  /* max-width: 400px; */
  display: block;
}
:deep .introduction p img {
  width: 100%;
  display: block;
}

.introduction pre {
  word-wrap: break-word !important;
  overflow-wrap: break-word;
  word-break: break-all !important;
  white-space: pre-wrap !important;
}

.introduction span {
  display: block;
  clear: both !important;
  width: 100%;
  word-wrap: break-word !important;
  word-break: break-all !important;
  white-space: pre-line !important;
}

.van-empty {
  margin: auto;
}

.toOption {
  padding: 10px;
  border: none;
  outline-color: none;
}

.mb-60 {
  padding-bottom: 60px;
}
</style>