import { render, staticRenderFns } from "./parkDetails.vue?vue&type=template&id=5650de38&scoped=true&"
import script from "./parkDetails.vue?vue&type=script&lang=js&"
export * from "./parkDetails.vue?vue&type=script&lang=js&"
import style0 from "./parkDetails.vue?vue&type=style&index=0&id=5650de38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5650de38",
  null
  
)

export default component.exports