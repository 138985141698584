<template>
  <div class="inviteVisitors">
    <van-form @submit="onSubmit" class="inviteContent">
      <!-- 业务选择 -->
      <van-field @focus="noBomBox" name="visitorType_text" v-model="form.visitorType_text" label="访问类型" required
        placeholder="请选择访问类型" @click="showPicker = true" :rules="[{ required: true }]" right-icon="arrow">
      </van-field>
      <!-- 选择业务访谈弹出框 -->
      <van-popup v-model="showPicker" position="bottom">
        <van-picker show-toolbar label="label" value-key="value" :columns="visiteTypeList" @confirm="onConfirm"
          @cancel="showPicker = false" />
      </van-popup>
      <!-- 访问园区 -->
      <van-field @focus="noBomBox" name="parkId" v-model="parkId" label="访问园区" required placeholder="请选择访问园区"
        @click="showPickerPark = true" :rules="[{ required: true }]" right-icon="arrow">
      </van-field>
      <!-- 选择访问园区弹出框 -->
      <van-popup v-model="showPickerPark" position="bottom">
        <van-picker show-toolbar label="label" value-key="value" :columns="parkList" @confirm="onConfirmPark"
          @cancel="showPickerPark = false" />
      </van-popup>
      <!-- 访问时间 -->
      <van-field @focus="noBomBox" clickable name="visitorTime" v-model="form.visitorTime" label="访问时间" required
        placeholder="请选择访问时间" @click="showPickerTime = true" :rules="[{ required: true }]" right-icon="arrow">
      </van-field>
      <van-popup v-model="showPickerTime" position="bottom">
        <van-datetime-picker type="datetime" :formatter="formatter" :min-date="minDate" @confirm="onConfirmTime"
          @cancel="showPickerTime = false" />
      </van-popup>
      <!-- 访问时长： -->
      <van-field @focus="noBomBox" name="visitorDuration" v-model="form.visitorDuration" label="访问时长" required
        placeholder="请选择访问时长" @click="showDuration = true" :rules="[{ required: true }]" right-icon="arrow">
      </van-field>
      <div v-show="form.visitorType_text">
        <!-- 姓名 -->
        <van-field name="visitorName" v-model="form.visitorName" label="访客姓名" placeholder="请填写访客姓名" required
          :rules="[{ required: true }]"></van-field>
        <!-- 手机号 -->
        <van-field name="visitorMobile" v-model="form.visitorMobile" type="tel" label="访客手机" required maxlength="11"
          placeholder="请填写访客手机" :rules="telRules">
        </van-field>
        <!-- 到访公司 -->
        <van-field name="toVisitCorp" v-model="form.toVisitCorp" label="公司名称" :rules="[{ required: true }]" required
          placeholder="请填写公司名称">
        </van-field>
        <!-- 随行人数 -->
        <van-field name="visitorCnt" type="number" v-model="form.visitorCnt" label="随行人数"
          placeholder="请填写随行人数"></van-field>
        <!-- 来访人车牌号 -->
        <van-field :value="form.visitorCarNo" readonly label="车牌号" @click="showcar = true" placeholder="请填写车牌号" />
        <key-board v-model="form.visitorCarNo" :show.sync="showcar"></key-board>
        <!-- 邀请人号码 -->
        <van-field name="inviterMobile" type="tel" maxlength="11" v-model="form.inviterMobile" label="邀请人号码"
          placeholder="请填写邀请人号码" :rules="telRules"></van-field>
      </div>
      <!-- 来访时间选择弹窗 -->
      <van-popup v-model="showDuration" position="bottom">
        <van-picker show-toolbar :columns="durationList" @confirm="onConfirmDuration" @cancel="showDuration = false" />
      </van-popup>
      <div class="toOption">
        <van-button round block native-type="submit">提交</van-button>
      </div>
    </van-form>
    <!-- 唤起分享： -->
    <van-share-sheet v-model="showShare" title="邀请函已生成" :options="shareOptions" @select="onSelect"
      description="请选择以下方式发给被邀请人"></van-share-sheet>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { add_vistor } from '@/api/invite'
import { get_repairtype_list } from "@/api/repair"
import { format } from '@/util/time'
import keyBoard from '@/components/keyboard.vue';

export default {
  name: "inviteVisitors",
  components: {
    keyBoard
  },
  data() {
    return {
      showcar: false,
      value1: '1222',
      message: 'nei',
      value: '',
      // 访问类型：
      visiteTypeList: [],
      // 访问园区
      parkList: [],
      durationList: ['15分钟', '30分钟', '45分钟', '60分钟', '2小时', '3小时', '4小时', '1天'],
      showPicker: false,
      showPickerPark: false,
      showPickerTime: false,
      showDuration: false,
      parkId: '',
      form: {
        visitorName: '', // 访客姓名
        visitorMobile: '', // 访客手机
        visitorIdCard: '', //  访客身份证
        toVisitCorp: '', //  到访公司
        visitorCnt: '', //  随行人数
        visitorSubject: '', //  来访事由
        temperature: '', //  体温
        remarks: '', //   备注
        visitorType: '', // =9 访问类型        访问主动申请来访的 登记字段
        visitorType_text: '',
        visitorTime: '', //来访时间
        visitorDuration: '', // 访问时长
        inviterMobile: '', // 邀请人号码
        visitorCarNo: '', // 访客车牌
        status: "0",
        parkId: '', // 选择园区
        // park_text: '',
      },
      minDate: new Date(),
      repairMobile: '',
      showShare: false, // 分享
      shareOptions: [
        { name: '微信', icon: 'wechat' },
      ],
      id: '',// 分享订单id
      // 校验手机号码
      telRules: [{
        required: true,
        message: '手机号码不能为空',
        trigger: 'onBlur'
      }, {
        // 自定义校验规则
        validator: (value) => {
          return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)
        },
        message: '请填写正确格式的手机号码',
        trigger: 'onBlur'
      }],
    };
  },
  computed: {
  },
  created() {
    this.getPhoneNum()
  },
  activated: function () {
    this.getPhoneNum(); // 从cookie获取用户手机号 
    this.getVisiteType(); // 获取访问类型
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    // 禁止调起键盘
    noBomBox() {
      document.activeElement.blur();
    },
    // 从cookie获取用户手机号 
    getPhoneNum() {
      this.$set(this.form, 'inviterMobile', Cookies.get('repairMobile'))
    },
    // 获取访问类型：
    getVisiteType() {
      const pageParkId = Cookies.get('parkId') ?? '0' // 默认值
      get_repairtype_list({ dictId: '1581176106007339010' }).then((res) => {
        const visiteTypeList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          label: e.itemValue,
        }));
        this.visiteTypeList = visiteTypeList.filter((item) => {
          return item.value !== '门岗登记'
        })
      })
      // 获取园区列表
      get_repairtype_list({ dictId: '1592502229513187329' }).then((res) => {
        const getParkList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          label: e.itemValue,
        }));
        this.parkList = getParkList
        let chooseItem = getParkList.filter((item) => {
          return item.label === pageParkId
        })
        this.onConfirmPark(chooseItem[0]);
      })
    },
    onClickLeft() {
      this.$router.push({
        path: `/inviteChoose`
      })
    },
    // 提交表单：
    onSubmit() {
      this.form.visitorCnt = this.form.visitorCnt ? this.form.visitorCnt : 0
      add_vistor(JSON.parse(JSON.stringify(this.form))).then((res) => {
        if (res.data.code === 200) {
          this.showShare = true
          this.id = res.data.message
        }
      })
    },
    // 选择分享
    onSelect(option) {
      const parkId = Cookies.get('parkId');// 默认值
      const inviterMobile = Cookies.get('repairMobile');
      const realname = Cookies.get("realname");
      if (option.name === '微信') {
        // let databody = JSON.stringify({ "h5": "http://w.dmp.ydcz.cn/index.html#/inviteVisitors", "wx": "/pages/inviteInformation/inviteInformation?id=" + this.id + "&parkId=" + parkId + "&inviterMobile=" + inviterMobile, "shareTxt": this.form.visitorName + "邀请您来访" + this.parkId })
        let databody = JSON.stringify({ "h5": "http://w.dmp.ydcz.cn/index.html#/inviteVisitors", "wx": "/pages/inviteInformation/inviteInformation?id=" + this.id + "&parkId=" + parkId + "&inviterMobile=" + inviterMobile, "shareTxt": realname+"邀请您来访" + this.parkId })
        alert(`[share]${databody}`)
      } else {
        // this.$toast('已复制内容');
        window.location.href = 'sms://' + option.name
      }
      this.showShare = false;
    },
    // 业务访谈选择：
    onConfirm(val) {
      this.$set(this.form, 'visitorType_text', val.value)
      this.$set(this.form, 'visitorType', val.label)
      this.showPicker = false;
    },
    // 选择园区
    onConfirmPark(val) {
      this.parkId = val.value
      this.$set(this.form, 'parkId', val.label)
      this.showPickerPark = false;
    },
    // 来访时间选择
    onConfirmDuration(value) {
      this.form.visitorDuration = value;
      this.showDuration = false;
    },
    formatter(type, value) {
      // 格式化选择器日期
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      }
      return value
    },
    onConfirmTime(d) {
      // console.log('选中时间', d)
      if (d < new Date().getTime()) {
        this.$toast('不得小于当前时间');
        return false
      }
      this.showPickerTime = false;
      this.form.visitorTime = format(d)
    },

  }
}
</script>

<style scoped>
.inviteVisitors {
  height: 100vh;
  background-color: #ffffff;
}

.inviteContent {
  padding-bottom: 60px;
}

::v-deep(.van-cell) {
  display: block;
}

:deep(.van-field__label) {
  line-height: 40px;
}

:deep(.van-cell--required::before) {
  top: 20px;
}

:deep(.van-field__control) {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

::v-deep .van-field__control {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}</style>
