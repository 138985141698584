<template>
  <div class="Invite">
    <van-tabs @click="onClickTab" class="inviteTab">
      <van-tab title="本周"></van-tab>
      <van-tab title="全部"></van-tab>
    </van-tabs>
    <div class="invite-box" ref="invitebox" @scroll="scrollLoad($event)">
      <div class="invite-item" v-for="item in inviteList" :key="item.message" @click="toInviteDetail(item)">
        <div class="item-left">
          <p class="invite-title">
            <img :src="require('../../assets/img/invite.png')" class="icon-size" />
            {{ item.visitorType_dictText }}
          </p>
          <p class="fs-16 fw_600">{{ item.visitorName }}</p>
          <h3 class="item-name">{{ item.inviteName }}</h3>
          <p class="invite-subject">到访公司：{{ item.toVisitCorp }}</p>
          <p>受邀人电话：<span class="fw_600">{{ item.visitorMobile }}</span></p>
          <p class="fw_600"><van-icon name="underway-o" size="18" /> {{ item.visitorTime }}（{{ item.visitorDuration }}）
          </p>
        </div>
        <div class="item-right">
          <p><span class="state whiteTColor"
              :class="[item.status === '0' ? 'blueBg' : (item.status === '1' || item.status === '3' || item.status === '4') ? 'successBg' : 'infoBg']">{{
                item.status_dictText
              }}</span></p>
          <div class="userImg"><img :src="item.userImg"></div>
        </div>
      </div>
      <div v-if="moreTile">
        <span @click="more()" v-if="!nomoredata">加载更多</span>
        <span v-if="nomoredata">没有更多了</span>
      </div>
    </div>
    <div v-if="!inviteList.length">
      <van-empty description="暂无数据" />
    </div>

    <div class="toOption">
      <van-button @click="toChooseInvite()" size="large">邀请访客</van-button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { get_visitors_list } from '@/api/invite'
import { get_repairtype_list } from "@/api/repair"
import { getMonday } from '@/util/time'
export default {
  name: "Invite",
  components: {},
  data() {
    return {
      // active: 0,
      // inviteListType: ['本周', '全部访客'],
      // activeName: 'a',
      // visible: false,
      // activeIndex: '1',
      tabType: 0, //
      // 列表循环：
      inviteList: [],
      repairMobile: '',// 手机号：
      pageNo: 1,
      pageSize: 10,
      moreTile: false,
      nomoredata: false,
      // 访客状态列表：
      stateTitleList: [],
      // 头像：
      userImg: [
        { userImg: require("../../assets/img/userimg_1.png") },
        { userImg: require("../../assets/img/userimg_2.png") },
        { userImg: require("../../assets/img/userimg_3.png") }
      ]
    };
  },
  computed: {
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.onClickTab(this.tabType);
  },
  destroyed() {
    this.inviteList = [],
      this.pageNo = 1,
      this.pageSize = 10
  },
  updated() { },
  methods: {
    // 点击tab
    onClickTab(name) {
      this.pageNo = 1,
      this.nomoredata = false,
      this.tabType = name;
      // console.log(name);
      this.inviteList = []
      this.getVisitorsList(name)
    },
    getVisiteType() {
      // 获取访客状态：
      get_repairtype_list({ dictId: '1591715684363456514' }).then((res) => {
        const stateTitleList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          lable: e.itemValue,
        }));
        this.stateTitleList = stateTitleList
      })
    },
    // 获取邀请访客列表
    getVisitorsList(name) {
      // 从cookie获取用户手机号 
      let repairMobile = Cookies.get('repairMobile')
      // let repairMobile = '18912345678'
      const data = {
        inviterMobile: repairMobile,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      if (name === 0) {
        data.visitorTime_begin = getMonday('s')
        data.visitorTime_end = getMonday('s', 1)
      }
      if (repairMobile) {
        get_visitors_list(data).then((res) => {
          let rst = res.data.result.records;
          // console.log('rst-status',rst)
          rst.map((i, index) => {
            i.visitorTime = i.visitorTime.slice(5, 16)
            if ((index % 2) === 0) {
              i.userImg = this.userImg[1].userImg
            }else if((index % 3) === 0) {
              i.userImg = this.userImg[2].userImg
            }else {
              i.userImg = this.userImg[0].userImg
            }
          })
          if (typeof rst === 'object') {
            if (rst.length < 1) {
              // this.$toast('没有更多了');
              this.nomoredata = true;
              return;
            }
            this.inviteList.push(...rst);
          } else {
            // console.log('数据获取', res.data.result.records);
          }
        }).catch(e => {
          console.log('数据请求失败', e);
        });
      } else {
        this.$toast("暂未查到手机号码")
      }

    },
    // 查看邀请的访客详情
    toInviteDetail(even) {
      this.$router.push({
        path: `/inviteDetail`,
        query: {
          id: even.id
        }
      })
    },
    // 选择邀请访客
    toChooseInvite() {
      this.$router.push({
        path: `/InviteChoose`,
      })
    },
    // 滑到底加载：
    handleScroll() {
      const $list = this.$refs.invitebox;
      let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
      const theclientHeight = `${document.documentElement.clientHeight}`;
      const all = Number(top) + Number(theclientHeight)
      if (all >= $list.scrollHeight) {
        if (!this.moreTile) {
          setTimeout(() => {
            this.moreTile = true
          }, 1000)
        }
      }
    },
    // 加载更多
    more() {
      if (!this.nomoredata) {
        this.pageNo++;
        this.getVisitorsList(this.tabType);
      } else {
        this.$toast('没有更多了')
      }
    },
  }
}
</script>

<style scoped>
.Invite {
  padding-bottom: 45px;
}

.inviteTab {
  position: fixed;
  width: 100vw;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.invite-box {
  padding: 55px 10px 80px 10px;
  overflow: auto;
}

/* 访客列表 */
.invite-item {
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.invite-title {
  line-height: 40px;
}

.icon-size {
  width: 20px;
  vertical-align: middle;
  font-size: 16px !important;
}

.invite-title-img {
  display: inline;
  width: 20px;
  height: 20px;
  margin: 2px 5px;
  vertical-align: middle;
}

.invite-item .item-left {
  width: 70%;
  float: left;
  text-align: left;
}

.invite-item .item-right {
  width: 30%;
  float: right;
  text-align: right;
}

.invite-item .item-name {
  font-size: 16px;
  line-height: 32px;
}

.invite-item .userImg {
  margin-top: 20px;
}

.invite-item .userImg img {
  width: 50px;
  height: 50px;
  float: right;
}

.invite-item .invite-subject {
  color: #b3b2b2;
}

.toOption {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  /* width: calc(100% - 20px); */
  text-align: center;
  padding: 10px;
}

.toOption button {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  background-color: #5399FF;
  color: #ffffff;
}

/* 邀请访客明细 */
.repair-detail {
  position: relative;
}

.repair-detailBg {
  /* position: absolute; */
  width: 100%;
  height: 260px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* border: 1px solid red; */
}

.repair-detailBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px;
}

.stateTitle {
  margin-top: 40px;
}

.intervieweeCard {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
}

.user-img {
  width: 80px;
  margin: 15px auto;
  text-align: center;
}

.optionBox {
  width: 50%;
  margin: 20px auto;
  overflow: auto;
  text-align: center;
}

.optionLeft {
  float: left;
  text-align: center;
}

.optionRight {
  float: right;
}

::v-deep .van-tabs__line {
  background-color: #5399FF;
}

::v-deep .van-tab--active {
  color: #5399FF;
}

::v-deep .van-icon {
  top: 2px;
}</style>
