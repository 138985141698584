<!-- 投诉 -->
<template>
  <div class="complaintDetails">
    <div class="category-box">
      <van-form>
        <div class="categoryCen">
          <van-field name="picker" label-width="80px" :value="list.repairType_dictText" label="投诉类型:"
            placeholder="请选择投诉类型" @click="showPicker = true" readonly clickable />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar value-key="value" :columns="projectList" @confirm="onConfirm"
              @cancel="showPicker = false" />
          </van-popup>
          <div>
            <van-field label-width="80px" v-model="list.repairContent" label="投诉内容:" rows="4" autosize type="textarea" maxlength="150" placeholder="请填写投诉内容"
              show-word-limit />
            <br />
            <van-uploader v-if="!this.$route.query.pageid" v-model="fileList" :after-read="afterRead"></van-uploader>
            <van-uploader v-if="this.$route.query.pageid" v-model="fileList" :deletable="false" :readonly="true" :show-upload="false"></van-uploader>
          </div>
          <div>
            <p>联系电话</p>
            <h4 class="fs-16 fw_600">{{ list.repairMobile }}</h4>
          </div>
          <br>
          <p v-if="this.$route.query.pageid">处理时间：{{ detail.updateTime??'暂无处理结果' }}</p>
          <p v-if="this.$route.query.pageid">处理人：{{ detail.updateBy??'暂无处理结果' }}</p>
          <p v-if="this.$route.query.pageid">处理结果：{{ detail.compResult??'暂无处理结果' }}</p>
        </div>
        <div class="toOption" v-show="submitShow">
          <van-button @click="onSubmit()" size="large">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from "js-cookie";
import baseURL from '../../util/config';
import imgUrl from '@/util/imgUrl';
import { get_complaints_detail, add_complaints, get_repairtype_list } from "@/api/repair"
export default {
  name: "complaintDetails",
  components: {},
  data() {
    return {
      showPicker: false,
      projectList: [],
      detail: {
        updateTime: '',
        updateBy: '',
        compResult: ''
      },
      list: {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: '',
        repairType: '',
        updateTime: '',
        updateBy: '',
        compResult: '',
        parkId:'',
      },
      //上传
      fileList: [],
      imgurl: '',
      submitShow: true, // 提交按钮显示
      imgList: [],
    };
  },
  computed: {
  },
  created() {
    this.getPhoneNum();
  },
  activated: function () {
    this.getPhoneNum();
    this.getRepairtype();
    this.repairsDetail()
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    getPhoneNum() {
      this.list.repairMobile = Cookies.get('repairMobile')
      //this.list.parkId = Cookies.get('parkId')
    },
    // 点击返回
    onClickLeft() {
      this.$router.push({
        path: `/complaint`
      })
    },
    onConfirm(val) {
      this.$set(this.list, 'repairType', val.label)
      this.$set(this.list, 'repairType_dictText', val.value)
      this.showPicker = false;
    },
    // 获取投诉类别
    getRepairtype() {
      get_repairtype_list({ dictId: '1580904978596700162' }).then((res) => {
        const projectList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          label: e.itemValue,
        }));
        this.projectList = projectList
        this.progectType(this.$route.query.repairType, this.$route.query.repairType_dictText)
      })
    },
    // 页面信息显示选择类型
    progectType(type, name) {
      this.onConfirm({ label: type, value: name });
    },
    // 获取投诉详情
    repairsDetail() {
      let pageid = this.$route.query.pageid
      if (pageid) {
        this.submitShow = false
        get_complaints_detail({ id: pageid }).then((res) => {
          this.detail = res.data.result;
          this.list.repairContent = res.data.result.compContent;
          res.data.result?.compPic ? this.fileList = res.data.result?.compPic?.split(",").map((i) => {
            return { url: imgUrl + i}
          }) : this.fileList = []
        })
      } else {
        this.submitShow = true
        this.reset4add()
      }
    },
    reset4add() {
      this.list = {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: this.list.repairMobile,
        repairType: '',
        parkId: Cookies.get('parkId'),
      }
      this.detail = {}
      this.fileList = []
      this.imgList = []
    },
    // 上传：
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData()
      formData.append('file', file.file)
      axios({
        method: 'post',
        headers: {
          'X-Access-Token': Cookies.get('X-Access-Token')
        },
        url: `${baseURL}sys/common/upload`,
        data: formData
      }).then((res) => {
        if (res.data.success == true) {
          // 上传状态提示关闭
          file.status = "done";
          this.$toast("上传成功！");
          this.imgList.push(res.data.message)
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 上传
    beforeRead(file) {
      const type = ['image/jpeg', 'image/png']
      const isImage = type.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isImage) {
        this.$toast('上传图片格式不正确')
      }
      if (!isLt2M) {
        this.$toast('图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    // 上传结束
    // 提交
    onSubmit() {
      const repairData = {
        compType: this.list.repairType,
        compContent: this.list.repairContent,
        compPic: this.imgList.join(','),
        compMobile: this.list.repairMobile,
        compState: 0,
        parkId: this.list.parkId,
      }
      add_complaints(JSON.parse(JSON.stringify(repairData))).then((res) => {
        if (res.data.code === 200) {
          this.$toast('提交成功')
          this.onClickLeft();
          this.reset4add();
        }
      }).catch((err) => {
        this.$toast(err)
      })
    },
  }
}
</script>

<style scoped>
.repairDetails {
  background: #ffffff;
  height: 100vh;
}

.category-box {
  padding: 10px;
  padding-bottom: 45px;
  text-align: left;
  background-color: #ffffff;
}
.categoryCen {
  padding-bottom: 55px;
}
.musticon {
}

:deep .van-tab span {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  padding: 3px;
}

:deep .van-tab--active span {
  border-radius: 5px;
  border: 1px solid #409EFF;
}

::v-deep .van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 1;
  height: 44px;
  padding: 0 10px;
  background-color: #dddddd47;
  border-radius: 30px;
  box-sizing: content-box;
}

::v-deep .van-tab--active {
  font-weight: bold;
}

:deep(.van-field__control) {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
::v-deep .van-field__control{
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
::v-deep .van-field__label {
  text-align: right;
}
</style>
