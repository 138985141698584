<!-- 会议室 -->
<template>
  <div class="meetingRoom">
    <van-nav-bar title="定制会务" class="backBox" left-arrow @click-left="onClickLeft"></van-nav-bar>
    <div class="meetingRoomCent">
      <div class="meetingBox" v-for="(item, index) in meetingList" :key="index" @click="toMeetingDetail(item)">
        <img :src="item.pic" class="meetingImg" />
        <div class="meetingCen">
          <p>
            <van-tag round type="primary" class="fs-12">{{ item.status }}</van-tag>&nbsp;<span class="ltext">{{ item.roomName }}</span>
          </p>
          <p>
            <van-tag plain type="primary">{{ item.areaCapacity }}</van-tag>
          </p>
          <p class="mtext">{{ item.location }}</p>
        </div>
        <div class="optionBox">
          <p class="flex-1 text-left optionPrice">¥{{ item.price }}</p>
          <van-button class="optionBtn flex-shrink-0" round @click="submit(item.orderPhone)">立即预订</van-button>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import baseURL from "../../util/config"
import { get_meeting_list } from "@/api/meeting"
export default {
  name: 'meetingRoom',
  data() {
    return {
      meetingList: [],
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    // 获取会议室列表
    getList() {
      get_meeting_list({pageNo: 1, pageSize: 10}).then((res) => {
        if (res.data.code === 200) {
          const meetingList = res.data.result?.records.map((e) => ({
            ...e,
            pic: baseURL + e.pic
          }));
          this.meetingList = meetingList
        }
      }).catch(() => {
        this.meetingList = []
      })
    },
    // 拨打电话
    submit(tell) {
      window.location.href = 'tel:' + tell
    },
    // 查看邀请的访客详情
    toMeetingDetail(even) {
      this.$router.push({
        path: `/meetingDetail`,
        query: {
          id: even.id
        }
      })
    },
    // 点击返回
    onClickLeft() {
      alert(`[close]`)
    },
  }
}
</script>
<style scoped>
.meetingRoom {
  /* padding: 10px; */
}
.backBox{
  padding-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  z-index: 1;
  position: fixed;
  font-size: 16px !important;
}
.meetingRoomCent {
  margin-top: 80px;
  overflow: hidden;
}
.meetingBox {
  background: #ffffff;
  border-radius: 10px;
  margin: 15px;
}
.meetingImg {
  width: 100%;
  border-radius: 10px;
}
.meetingCen{
  text-align: left;
  padding: 10px;
}
/* .meetingCen p span {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
} */
.optionBox {
  border-top: 1px solid rgb(241, 241, 241);
  display: flex;
  padding: 10px;
}
.optionBtn {
  width: 110px;
  border: none;
  color: #ffffff;
  background: #409EFF;
  font-size: 16px;
}
.text-left{ 
  text-align: left;
}
.optionPrice {
  font-size: 20px;
  line-height: 44px;
  font-weight: bold;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1 1 0%;
}
.van-tag--round {
  padding: 2px 7px;
  font-size: 14px;
  line-height: initial;
}
.van-tag--plain {
  padding: 3px 8px;
  font-size: 14px;
}
:deep .van-nav-bar__title {
  font-size: 18px;
}
:deep .van-nav-bar__arrow{
  font-size: 24px;
}
:deep .van-nav-bar .van-icon{
  color: #999;
}
.ltext {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  vertical-align: middle;
}
.mtext {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}
</style>