// 邀请访客
import request from '../util/request';

// 新增访客（
export function add_vistor(data) {
  return request({
    method: 'POST',
    url: '/dmp/dmpVisitors/add',
    data,
  })
}

// 查询受邀访客列表（pageNo=1&pageSize=10&inviterMobile=18912345678）
export function get_visitors_list(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpVisitors/list',
    params: data,
  })
}

// 受邀请访客详情（id=1582986826432798722）
export function get_visitor_detail(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpVisitors/queryById',
    params: data,
  })
}
