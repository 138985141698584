<!-- 投诉 -->
<template>
  <div class="complaint">
    <div class="repair-detail">
      <!-- 背景 -->
      <div class="repair-detailBg blueBg"></div>
      <!-- 内容 -->
      <div class="repair-detailBox">
        <!-- 名牌 -->
        <div class="repair-card">
          <div class="repair-project" v-for="item in projectList" :key="item.label" @click="toComplaint(item,'type')">
            <p class="img-box"><img :src="item.src" alt="img"></p>
            <p>{{ item.repairType_dictText }}</p>
          </div>
          <div class="repair-orther">
            <p class="tip-p">投诉记录：</p>
            <van-empty v-if="!repairList.length" description="暂无记录" />
            <div v-for="i in repairList" :key="i.id" class="box-body" @click="toComplaint(i,'detail')">
              <h4 class="fs-20"><span>{{ i.compType_dictText }}</span></h4>
              <p class="grayTColor">
                {{ i.createTime }}
                <van-tag class="ml-10" plain type="primary" :color="i.compState===2?'#999999':'#5399FF'">{{i.compState_dictText}}</van-tag>
              </p>
              <p>{{ i.compContent }}</p>
              <van-cell class="tl" title="详情" is-link :key="i.id"></van-cell>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { get_complaints_list } from "@/api/repair"
export default {
  name: "complaint",
  components: {},
  data() {
    return {
      state: true,
      repairTitle: '投诉',
      projectList: [
        {
          src: require('@/assets/img/kongtiao.png'),
          repairType_dictText: '暖通空调',
          repairType: 'ntkt',
        },
        {
          src: require('@/assets/img/tingchechang.png'),
          repairType_dictText: '停车场',
          repairType: 'tcc',
        },
        {
          src: require('@/assets/img/anquanguanli.png'),
          repairType_dictText: '安全管理',
          repairType: 'aqgl',
        },
        {
          src: require('@/assets/img/fenleiorguangchangorqita.png'),
          repairType_dictText: '其他服务',
          repairType: 'qtfw',
        },
        {
          src: require('@/assets/img/weixiu.png'),
          repairType_dictText: '入户维修',
          repairType: 'rhwx',
        },
        {
          src: require('@/assets/img/huanbaopingtai-huanjingguanli.png'),
          repairType_dictText: '环境管理',
          repairType: 'hjgl',
        },
        {
          src: require('@/assets/img/zonghesuzhipingjia.png'),
          repairType_dictText: '人员素质',
          repairType: 'rysz',
        },
      ],
      repairList: [],
      repairMobile: '',
      // parkId: Cookies.get('parkId') // 默认值
    };
  },
  computed: {
  },
  created() {
    this.getPhoneNum();
  },
  activated: function () {
    this.getPhoneNum();
    this.getList()
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    // 从cookie获取用户手机号 
    getPhoneNum() {
      this.repairMobile = Cookies.get('repairMobile')
    },
    // 去投诉页面
    toComplaint(even, part) {
      if(part === 'type'){
        this.$router.push({
          path: `/complaintDetails`,
          query: {
            repairType: even.repairType,
            repairType_dictText: even.repairType_dictText,
            pageid: even.id
          }
        })
      }else{
        this.$router.push({
          path: `/complaintDetails`,
          query: {
          repairType: even.compType,
          repairType_dictText: even.compType_dictText,
          pageid: even.id
          }
        })
      }
    },
    // 获取我的维修记录
    getList() {
      this.repairMobile = Cookies.get('repairMobile')
      get_complaints_list({ compMobile: this.repairMobile,pageNo:1, pageSize: 10 }).then((res) => {
        if (res.data.code === 200) {
          this.repairList = res.data.result.records
        }
      })
    }
  }
}
</script>

<style scoped>
.repair-detail {
  position: relative;
}

.repair-detailBg {
  width: 100%;
  height: 260px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.repair-detailBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 30px;
  padding: 10px;
  height: 100vh;
}

/* 报修 */
.repairTitle {
  text-align: center;
  line-height: 80px;
}

.repair-card {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.repair-project {
  width: 25%;
  margin-bottom: 15px;
  text-align: center;
}

.repair-project img {
  width: 50px;
  margin: 0 auto;
}

.repair-title {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
}

.category-box {
  padding: 10px;
  background-color: #ffffff;
}

.repair-content {}

.repair-orther {
  width: 100%;
  text-align: center;
}

.box-body {
  text-align: left;
  padding: 10px;
}

.van-cell {
  padding: 0;
}

.img-box {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #dbeaf9;
  border-radius: 50%;
}

.img-box img {
  width: 58%;
  text-align: center;
  vertical-align: middle;
}
:deep .van-tag--plain{
  padding: 2px 4px;
  text-align: center;
  vertical-align: middle;
}
</style>
