// 常见问题
import request from '../util/request';

// 常见问题列表
export function getProblemList(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpFaqs/list',
    params: data,
  })
}
// 问题详情
export function get_problem_details(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpFaqs/queryById',
    params: data,
  })
}