// 会议室
import request from '../util/request';
// 获取会议室列表：
export function get_meeting_list(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpMeetingPlace/list',
    params: data,
  })
}
// 会议室详情页面
export function get_meeting_detail(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpMeetingPlace/queryById',
    params: data,
  })
}

