// 获取链接里的参数：
export function getParamQueryString (url, name) {
  let subStr = name + '='
  if (url.indexOf(subStr) !== -1) {
    let arr = url.split(subStr)
    let sub1 = arr[1]
    if (sub1.indexOf('&') !== -1) {
      let arr2 = sub1.split('&')
      let sub2 = arr2[0]
      return sub2
    } else {
      return sub1
    }
  }
  return ''
}
// // 找到visiteType的value
// export const currentIdentity = function (customerIdentity) {
//   return customerIdentityArray.find((i) => i.customerIdentity === customerIdentity);
// };