<template>
  <div class="InviteDetail">
    <div class="repair-detail">
      <!-- 背景 -->
      <div class="repair-detailBox">
        <div class="intervieweeCard">
          <h2 class="tipBox whiteTColor"
            :class="[detail.status === '0' ? 'blueBg' : (detail.status == '1' || detail.status == '3' || detail.status == '4' ) ? 'successBg' : 'infoBg']">{{ detail.stateTitle
            }}
          </h2>
          <!-- 头像 -->
          <img class="user-img" src="" alt="">
          <h2>{{ detail.visitorName }}</h2>
          <p class="grayTColor">手机号：{{ detail.visitorMobile }}</p>
          <!-- 分享操作： -->
          <div class="optionBox">
            <div class="optionLeft" @click="toInvite">
              <p class="share blueBg">
                <van-icon name="share-o" size="30" color="#fff" />
              </p>
              <p>分享邀请</p>
            </div>
            <div class="optionRight" @click="submit">
              <p class="call successBg">
                <van-icon name="phone-o" size="30" color="#fff" />
              </p>
              <p>联系访客</p>
            </div>
          </div>
          <!-- 业务类型： -->
          <div class="inviteTip">
            <div class="optionLeft">
              <!-- <h3>业务访谈</h3> -->
              <h3>{{ detail.visitorTypeTitle }}</h3>
              <p>访问类型</p>
            </div>
            <div class="optionRight">
              <!-- <h3>09-16 13:00</h3> -->
              <h3>{{ detail.visitorTime }}</h3>
              <p>到访时间</p>
            </div>
          </div>
        </div>
        <!-- 详情部分： -->
        <div class="inviteDetail">
          <h3>访问详情</h3>
          <div class="inviteDetaiItem">
            <p>访客姓名</p>
            <h4>{{ detail.visitorName }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>访客手机</p>
            <h4>{{ detail.visitorMobile }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>访客身份证</p>
            <h4>{{ detail.visitorIdCard ?? '未填写' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>到访公司</p>
            <h4>{{ detail.toVisitCorp ?? '无' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>随行人数</p>
            <h4>{{ detail.visitorCnt ?? '无' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>来访者车牌</p>
            <h4>{{ detail.visitorCarNo ?? '无' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>来访事由</p>
            <h4>{{ detail.visitorSubject ?? '无' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>体温</p>
            <h4>{{ detail.temperature ?? '未填写' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>备注</p>
            <h4>{{ detail.remarks ?? '无' }}</h4>
          </div>
          <div class="inviteDetaiItem">
            <p>访问类型</p>
            <h4>{{ detail.visitorTypeTitle }}</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- 唤起分享： -->
    <van-share-sheet v-model="showShare" title="邀请函已生成" :options="shareOptions" @select="onSelect"
      description="请选择以下方式发给被邀请人"></van-share-sheet>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { get_visitor_detail } from '@/api/invite'
import { get_repairtype_list } from "@/api/repair"
import { getParamQueryString } from '@/util/getUrlData'
export default {
  name: "InviteDetail",
  components: {},
  data() {
    return {
      detail: {
        state: true,
        stateTitle: '',
        status: '',
        visitorName: '',
        visitorMobile: '',
        visitorTypeTitle: '',//访问类型文字
      },
      visiter: '',
      // 访问类型：
      visiteTypeList: [],
      // 访客状态列表：
      stateTitleList: [],
      showShare: false, // 分享
      shareOptions: [
        { name: '微信', icon: 'wechat' },
      ],
    };
  },
  mounted() {
    this.getVisitorsList();
  },
  activated: function () {
    this.getVisitorsList();
  },
  methods: {
    getVisType() {
      return get_repairtype_list({ dictId: '1581176106007339010' }).then((res) => {
        const visiteTypeList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          lable: e.itemValue,
        }));
        return { visiteTypeList }
      })
    },
    getRepairType() {
      return get_repairtype_list({ dictId: '1591715684363456514' }).then((res) => {
        const stateTitleList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          lable: e.itemValue,
        }));
        return { stateTitleList }
      })
    },
    // 分享邀请:
    toInvite() {
      this.showShare = true
    },
    // 选择分享
    onSelect(option) {
      const parkId = Cookies.get('parkId');// 默认值
      const inviterMobile = Cookies.get('repairMobile');
      if (option.name === '微信') {
        let databody = JSON.stringify({ "h5": "http://w.dmp.ydcz.cn/index.html#/inviteDetail", "wx": "/pages/inviteInformation/inviteInformation?id=" + this.visiter + "&parkId=" + parkId + "&inviterMobile=" + inviterMobile })
        alert(`[share]${databody}`)
      } else {
        window.location.href = 'sms://' + option.name
      }
      this.showShare = false;
    },

    // 获取邀请的访客详情
    async getVisitorsList() {
      const { visiteTypeList } = await this.getVisType();
      const { stateTitleList } = await this.getRepairType();
      let id = getParamQueryString(window.location.href, 'id')
      if (id) {
        this.visiter = id; //visitor id, add by honjo, 12/14/2023
        await get_visitor_detail({ id }).then((res) => {
          this.detail = res.data.result
          this.detail.visitorTime = this.detail.visitorTime.slice(5,16)
          this.detail.visitorTypeTitle = visiteTypeList.find((i) => { return i.lable === res.data.result.visitorType }).value;
          this.detail.stateTitle = stateTitleList.find((i) => { return i.lable === res.data.result.status }).value;
        })
      }
    },
    // 拨打电话
    submit() {
      window.location.href = 'tel:' + this.detail.visitorMobile
    }
  }
}
</script>

<style scoped>
/* 邀请访客明细 */
.repair-detail {
  position: relative;
}

.repair-detailBg {
  width: 100%;
  height: 260px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.repair-detailBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px;
}

.stateTitle {
  /* margin: 40px 0; */
}

.intervieweeCard {
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
}

.tipBox {
  position: absolute;
  left: 20px;
  top: 0;
  padding: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.user-img {
  width: 80px;
  margin: 15px auto;
  text-align: center;
}

.optionBox {
  width: 50%;
  margin: 20px auto;
  overflow: auto;
}

.optionLeft {
  float: left;
  text-align: left;
}

.optionRight {
  float: right;
}

.share,
.call {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  display: table-cell;
  vertical-align: middle;
  line-height: 0;
}

.share img,
.call img {
  display: block;
  width: 40px;
  height: 40px;
  margin: 5px auto;
}

.inviteTip {
  width: 80%;
  margin: 20px auto;
  text-align: center;
  overflow: auto;
}

.inviteDetail {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
}

.inviteDetail h3 {
  line-height: 35px;
}

.inviteDetaiItem:not(:last-child) {
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
}

.inviteDetaiItem p,
.inviteDetaiItem h4 {
  line-height: 30px;
}
</style>
