// 投诉/维修
import request from '../util/request';
// 新增维修
export function add_repairs(data) {
  return request({
    method: 'post',
    url: '/dmp/dmpRepairs/add',
    data,
  })
}

// 查自己的报修列表
// pageNo=1&pageSize=10&repairMobile=18612345678
export function get_repair_list(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpRepairs/list',
    params: data,
  })
}
// 报修详情（id=1583024896817389570）
export function get_repair_detail(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpRepairs/queryById',
    params: data,
  })
}

// 新增维修记录：
export function add_service(data) {
  return request({
    method: 'post',
    url: '/dmp/dmpRepairs/edit',
    data,
  })
}

// 获取报修/投诉类别
// 报修类别 dictId=1580903257384361985
// 投诉类别 dictId=1580904978596700162
export function get_repairtype_list(data) {
  return request({
    method: 'GET',
    url: '/sys/dictItem/list',
    params: data,
  })
}

/* 
** 投诉
 */
// 新增投诉
export function add_complaints(data) {
  return request({
    method: 'POST',
    url: '/dmp/dmpComplaints/add',
    data,
  })
}
// 投诉列表（pageNo=1&pageSize=10&compMobile=18911223344）
export function get_complaints_list(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpComplaints/list',
    params: data,
  })
}
// 投诉详情
// id=1583002070752321537
export function get_complaints_detail(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpComplaints/queryById',
    params: data,
  })
}

// 上传图片
// export function upload(data) {
//   return request({
//     headers: { //header也要加上
//       // 'Content-Type': 'multipart/form-data',
//       "Content-Type": "multipart/form-data;boundary=" + new Date().getTime()
//     },
//     url: '/sys/common/upload',
//     method: 'POST', // or 'PUT'
//     // data: JSON.stringify({file: data}),
//     data,
//   })
// }
// export function upload_img(data) {
//   return request({
//     url: `/sys/common/upload`,
//     method: 'post',
//     // headers: { 'Content-Type': 'multipart/form-data' },
//     data: data,
//   })
// }
// 获取维修处理情况列表
export function get_maintenance_list(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpRepairs/queryDmpRepairsRecByMainId',
    params: data,
  })
}
//增加拨打电话记录
export function add_phonecall(data) {
  return request({
    method: 'POST',
    url: '/dmp/dmpPhonecallRec/add',
    data,
  })
}