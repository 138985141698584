<!-- 报修详情页 -->
<template>
  <div class="repairDetails">
    <div class="category-box">
      <!-- 维修记录： -->
      <div v-if="recordsShow">
        <p class="fs-16 fw">
          <van-icon name="clock-o" />
          维修记录
        </p>
        <p v-if="!maintenanceList.length"><span>暂无记录</span></p>
        <div v-for="i in maintenanceList" :key="i.id" class="box-body flex">
          <div class="flex-1">
            <p class="grayTColor">{{ i.createTime }}</p>
            <p><span>{{ i.repairResultContent }}</span></p>
          </div>
          <div class="flex-shrink-0 w-80" @click="showFun(i.repairResultPic)">
            <img class="w-full" :src="'http://api.dmp.ydcz.cn/dmp-boot/sys/common/static/' + i.repairResultPic[0]" />
          </div>
        </div>
        <van-divider />
      </div>
      <van-form>
        <div>
          <!-- 报修内容：： -->
          <p class="fs-16 fw">
            <van-icon name="coupon-o" />
            报修内容
          </p>
          <van-field label-width="100" readonly clickable name="picker" :value="list.repairType_dictText" label="报修类型:"
            placeholder="请选择报修类型" @click="showPicker = true" />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar value-key="value" :columns="projectList" @confirm="onConfirm"
              @cancel="showPicker = false" />
          </van-popup>
          <!-- 报修楼幢/公司 -->
          <van-field label-width="100px" name="repairBuildNo" v-model="list.repairBuildNo" label="报修楼幢/公司:"
            :readonly="(this.$route.query.pageid) ? true : false" placeholder="请填写报修楼幢/公司"
            :rules="[{ required: true }]"></van-field>
          <div>
            <van-field label-width="100"  v-model="list.repairContent" rows="4" autosize label="报修内容:" type="textarea" maxlength="150"
              placeholder="请填写报修内容" :readonly="(this.$route.query.pageid) ? true : false" show-word-limit />
            <br />
            <van-uploader v-if="!this.$route.query.pageid" v-model="fileList" :after-read="afterRead"
              accept></van-uploader>
            <van-uploader v-if="this.$route.query.pageid" v-model="fileList" :deletable="false" :readonly="true"
              :show-upload="false"></van-uploader>
          </div>
          <div>
            <p>联系电话</p>
            <h4 class="fs-16 fw_600">{{ list.repairMobile }}</h4>
          </div>
        </div>
        <div class="toOption" v-show="submitShow">
          <van-button @click="onSubmit()" size="large">提交</van-button>
        </div>
      </van-form>
    </div>
    <!-- 弹窗 -->
    <van-dialog v-model="showImg" title="查看图片" :showCancelButton="false">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img class="dialogimg" :src="'http://api.dmp.ydcz.cn/dmp-boot/sys/common/static/' + image" />
        </van-swipe-item>
      </van-swipe>
    </van-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import axios from 'axios'
import baseURL from '../../util/config';
import imgUrl from '@/util/imgUrl';
import { get_repair_detail, add_repairs, get_repairtype_list, get_maintenance_list } from "@/api/repair"
export default {
  name: "repairDetails",
  components: {},
  data() {
    return {
      showPicker: false,
      // 报修类别
      projectList: [],
      list: {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: '',
        repairType: '',
        repairBuildNo: '',
        parkId: Cookies.get('parkId'),
      },
      //上传
      fileList: [],
      imgurl: '',
      imgList: [],
      submitShow: true, // 提交按钮显示

      // 维修记录列表：
      recordsShow: false,
      maintenanceList: [],
      showImg: false, // 图片弹窗
      images: [],
    };
  },
  created() {
    this.getPhoneNum();
  },
  mounted() {
    this.getPhoneNum();
  },
  activated: function () {
    this.getPhoneNum();
    this.getRepairtype();
    this.repairsDetail();
    this.getMaintenanceList();
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    // 从cookie获取用户手机号 
    getPhoneNum() {
      this.list.repairMobile = Cookies.get('repairMobile')
      // this.list.parkId = Cookies.get('parkId');// 默认值
    },
    onConfirm(val) {
      this.$set(this.list, 'repairType', val.label)
      this.$set(this.list, 'repairType_dictText', val.value)
      this.showPicker = false;
    },
    // 获取报修类别
    getRepairtype() {
      get_repairtype_list({ dictId: '1580903257384361985' }).then((res) => {
        const projectList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          label: e.itemValue,
        }));
        this.projectList = projectList
        this.progectType(this.$route.query.repairType, this.$route.query.repairType_dictText)
      })
    },
    // 页面信息显示选择类型
    progectType(type, name) {
      this.onConfirm({ label: type, value: name });
    },
    // 获取报修详情
    repairsDetail() {
      let pageid = this.$route.query.pageid
      if (pageid) {
        this.submitShow = false
        get_repair_detail({ id: pageid }).then((res) => {
          this.list.repairBuildNo = res.data.result.repairBuildNo,
          this.list.repairContent = res.data.result.repairContent,
            res.data.result?.repairPic ? this.fileList = res.data.result?.repairPic?.split(",").map((i) => {
              return { url: imgUrl + i }
            }) : this.fileList = []
        })
      } else {
        this.submitShow = true
        this.reset4add()
      }
    },
    reset4add() {
      this.list = {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: this.list.repairMobile,
        repairType: '',
        repairBuildNo: '',
        parkId: Cookies.get('parkId'),
      }
      this.fileList = []
      this.imgList = []
    },
    // 点击返回
    onClickLeft() {
      this.$router.push({
        path: `/repair`
      })
    },
    // 上传：
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData()
      formData.append('file', file.file)
      axios({
        method: 'post',
        headers: {
          'X-Access-Token': Cookies.get('X-Access-Token')
        },
        url: `${baseURL}sys/common/upload`,
        data: formData
      }).then((res) => {
        if (res.data.success == true) {
          // 上传状态提示关闭
          file.status = "done";
          this.$toast("上传成功！");
          this.imgList.push(res.data.message)
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 上传
    beforeRead(file) {
      const type = ['image/jpeg', 'image/png']
      const isImage = type.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isImage) {
        this.$toast('上传图片格式不正确')
      }
      if (!isLt2M) {
        this.$toast('图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    // 上传结束
    // 提交
    onSubmit() {
      const repairData = {
        repairType: this.list.repairType,
        repairContent: this.list.repairContent,
        repairPic: this.imgList.join(','),
        repairMobile: this.list.repairMobile,
        repairState: '0',
        repairBuildNo: this.list.repairBuildNo,
        parkId: Cookies.get('parkId'),
      }
      // this.$toast('parkid='+repairData.parkId)
      add_repairs(JSON.parse(JSON.stringify(repairData))).then((res) => {
        if (res.data.code === 200) {
          this.$toast('提交成功')
          this.onClickLeft();
          // this.reset4add();
        } else {
          this.$toast(res.message)
        }
      }).catch((err) => {
        this.$toast(err)
        console.log(err);
      })
    },

    // 获取维修处理情况列表
    getMaintenanceList() {
      let pageid = this.$route.query.pageid
      if (pageid) {
        this.recordsShow = true
        get_maintenance_list({ id: pageid }).then((res) => {
          this.maintenanceList = res.data.result
          this.maintenanceList = res.data.result.map((e) => ({
            ...e,
            repairResultPic: e.repairResultPic.split(","),
          }));
        })
      } else {
        this.recordsShow = false
      }
    },
    // 点击显示图片弹窗
    showFun(list) {
      this.showImg = true;
      this.images = list
    },
  }
}
</script>

<style scoped>
.repairDetails {
  background: #ffffff;
  height: 100vh;
}

.category-box {
  padding: 10px;
  padding-bottom: 55px;
  text-align: left;
  background-color: #ffffff;
}

.category-box p span {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.musticon {
  /* text-align: left; */
}

:deep .van-tab span {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  padding: 3px;
}

:deep .van-tab--active span {
  border-radius: 5px;
  border: 1px solid #409EFF;
}

:deep .van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 1;
  /* min- 50px; */
  height: 44px;
  padding: 0 10px;
  background-color: #dddddd47;
  border-radius: 30px;
  box-sizing: content-box;
}

:deep .van-tab--active {
  font-weight: bold;
}

:deep .van-cell {
  padding: 10px 0 !important;
}

.phoneinput {
  padding: 0 !important
}

.dialogimg {
  width: 80%;
  text-align: center;
}

:deep(.van-field__control) {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  /* line-height: 30px; */
}

::v-deep .van-field__control {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  /* line-height: 30px; */
}

::v-deep .van-field__label {
  text-align: right;
  /* text-align-last:justify; */
}
</style>
