import request from '../util/request';
// 新增需求文章页面
export function add_comp_demand(data) {
  return request({
    method: 'post',
    url: '/dmp/dmpCompDemand/add',
    data,
  })
}

// 编辑需求文章页面
export function edit_comp_demand(data) {
  return request({
    method: 'post',
    url: '/dmp/dmpCompDemand/edit',
    data,
  })
}

// 企业需求-根据id查询
export function get_cloud_demand(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpCompDemand/queryById',
    params: data,
  })
}
// export function get_demand_article(data) {
//   return request({
//     method: 'GET',
//     url: '/dmp/dmpCompDemand/queryById',
//     params: data,
//   })
// }