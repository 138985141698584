<template>
  <div class="meetingDetail">
    <div class="backBox">
      <button><van-icon name="arrow-left" color="#ffffff" @click="back()" /></button>
    </div>
    <img :src="pic" alt="" class="meetingBg">
    <div class="meetingCen">
      <h2 class="lineh-30 roomName">{{ detail.roomName }}</h2>
      <div class="minBox">
        <h3>开放时间</h3>
        <div class="timeBox">
          <van-steps class="time-item" direction="vertical" active-color="#999"
            v-for="(i, indexs) in times" :key="indexs">
            <p>{{ i }}</p>
            <van-step>
              {{ detail.openTime }}
            </van-step>
            <van-step>
              {{ detail.closeTime }}
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="minBox">
        <h3>设施服务</h3>
        <div class="flexBox">
          <p v-for="(item, index) in detail.equipment" :key="index" class="flex-item">
            <van-icon name="checked" color="#5399FF" />{{ item }}
          </p>
        </div>
      </div>
      <div class="minBox">
        <h3>预定须知</h3>
        <div>
          <div class="introduction" v-html="detail.orderNotice"></div>
        </div>
      </div>
    </div>
    <div class="toOption">
      <van-button round @click="submit(detail.orderPhone)">拨打电话预定</van-button>
    </div>
  </div>
</template>

<script>
import baseURL from "../../util/config"
import { get_meeting_detail } from "@/api/meeting"
export default {
  name: 'meetingRoom',
  data() {
    return {
      pageId: '',
      pic: '',
      detail: {
      },
      times: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
    }
  },
  activated(){
    this.getMeetingDetail()
  },
  methods: {
    // 会议室详情
    getMeetingDetail() {
      let pageId = this.$route.query.id
      get_meeting_detail({ id: pageId }).then((res) => {
        this.detail = res.data.result
        this.detail.equipment = res.data.result.equipment.split("，")
        this.detail.openTime = this.detail.openTime.substring(0, 5)
        this.detail.closeTime = this.detail.closeTime.substring(0, 5)
        this.pic = baseURL + res.data.result.pic
      })
    },
    // 拨打电话
    submit(tell) {
      window.location.href = 'tel:' + tell
    },
    // 返回
    back(){
      this.$router.push({
        path:`/meetingRoom`
      })
    }
  }
}
</script>
<style scoped>
.meetingDetail{
  padding-bottom: 45px;
}
.meetingCen {
  position: relative;
}
.p{
  font-size: 14px !important;
}
.meetingBg {
  width: 100%;
  height: 260px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.meetingCen {
  position: absolute;
  left: 0;
  min-height: 100%;
  right: 0;
  top: 220px;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
}
.roomName {
  text-align: left;
  margin: 20px 10px;
}
.timeBox {
  display: flex;
}

.time-item {
  flex: 1;
}
.van-steps--vertical{
  padding: 0 !important;
}
.van-step--vertical{
  padding: 0 !important;
}
.van-step {
  font-size: 6px;
}
.van-steps__items{
  margin-left: 20px !important;
}

.introduction {
  text-align: left;
  text-indent: 20px;
}
.minBox {
  margin-top: 10px;
  padding: 10px;
  text-align: left;
  line-height: 30px;
  font-size: 14px;
}

.flexBox {
  display: flex;
  justify-items: left;
  flex-wrap: wrap;
}
.flexBox p{
  font-size: 14px;
}
.flex-item {
  width: 33%;
}

.flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1 1 0%;
}
.van-step__icon, .van-step__title{
  font-size: 14px;
}
.van-steps__items p{
  font-size: 14px;
}
.van-steps__items div {
  font-size: 14px !important;
}
.introduction{
  font-size: 14px;
}
.backBox {
  padding-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: left;
}
.backBox button{
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border: 1px solid #999;
  border-radius: 50%;
  margin-left: 20px;
  background: rgba(0,0,0, 0.2) !important;
}
</style>