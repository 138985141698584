<!-- 报修 -->
<template>
  <div class="repair">
    <div class="repair-detail">
      <!-- 背景 -->
      <div class="repair-detailBg blueBg"></div>
      <!-- 内容 -->
      <div class="repair-detailBox">
        <!-- 名牌 -->
        <div class="repair-card">
          <div class="repair-project" v-for="item in projectList" :key="item.name" @click="toRepair(item)">
            <p class="img-box"><img :src="item.src" alt="img"></p>
            <p>{{ item.repairType_dictText }}</p>
          </div>
          <div class="repair-orther">
            <p class="tip-p">报修记录：</p>
            <van-empty v-if="!repairList.length" description="暂无记录" />
            <div v-for="i in repairList" :key="i.id" class="box-body" @click="toRepair(i)">
              <h4 class="fs-20"><span>{{ i.repairType_dictText }}</span></h4>
              <p class="grayTColor">
                {{ i.createTime }}
                <van-tag class="ml-10" plain type="primary" :color="i.repairState===2?'#999999':'#5399FF'">{{i.repairState_dictText}}</van-tag>
              </p>
              <p>{{ i.repairContent }}</p>
              <van-cell class="tl" title="详情" is-link :key="i.id"></van-cell>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { get_repair_list } from "@/api/repair"
export default {
  name: "repair",
  components: {},
  data() {
    return {
      state: true,
      repairTitle: '报修',
      projectList: [
        {
          src: require('@/assets/img/dianti.png'),
          repairType_dictText: '电梯',
          repairType: 'dt',
        },
        {
          src: require('@/assets/img/teshuanzhuang.png'),
          repairType_dictText: '安装拆除',
          repairType: 'azcc',
        },
        {
          src: require('@/assets/img/mianxingshuazifenshua.png'),
          repairType_dictText: '墙地面',
          repairType: 'qdm',
        },
        {
          src: require('@/assets/img/gongshuiguanli_0.png'),
          repairType_dictText: '水路管件',
          repairType: 'slgj',
        },
        {
          src: require('@/assets/img/menchuang.png'),
          repairType_dictText: '门窗桌椅',
          repairType: 'mczy',
        },
        {
          src: require('@/assets/img/dengju.png'),
          repairType_dictText: '灯具电路',
          repairType: 'djdl',
        },
        {
          src: require('@/assets/img/nuantongkongtiao.png'),
          repairType_dictText: '暖通空调',
          repairType: 'ntkt',
        },
        {
          src: require('@/assets/img/qitafuwu.png'),
          repairType_dictText: '其他',
          repairType: 'qt',
        },
      ],
      repairList: [],
      repairMobile: '',
    };
  },
  computed: {
  },
  beforeCreate(){
    
  },
  created() {
    this.getPhoneNum();
  },
  activated: function () {
    this.getPhoneNum();
    this.getList()
  },
  methods: {
    // 从cookie获取用户手机号 
    getPhoneNum() {
      this.repairMobile = Cookies.get('repairMobile')
    },
    // 去报修页面
    toRepair(even) {
      this.$router.push({
        path: `/repairDetails`,
        query: {
          repairType: even.repairType,
          repairType_dictText: even.repairType_dictText,
          pageid: even.id
        }
      })
    },
    // 获取我的报修记录
    getList() {
      this.repairMobile = Cookies.get('repairMobile')
      get_repair_list({ repairMobile: this.repairMobile }).then((res) => {
        if (res.data.code === 200) {
          this.repairList = res.data.result.records
        }
      }).catch(()=>{
        this.repairList = []
      })      
    }
  }
}
</script>

<style scoped>
.repair-detail {
  position: relative;
  height: 100vh;
}

.repair-detailBg {
  width: 100%;
  height: 260px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.repair-detailBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 30px;
  padding: 10px;
}

/* 报修 */
.repairTitle {
  text-align: center;
  line-height: 80px;
}

.repair-card {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.repair-project {
  width: 25%;
  margin-bottom: 15px;
  text-align: center;
}

.repair-project img {
  width: 50px;
  margin: 0 auto;
}

.repair-title {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
}

.category-box {
  padding: 10px;
  background-color: #ffffff;
}

.repair-content {}

.repair-orther {
  width: 100%;
  text-align: center;
}

.box-body {
  text-align: left;
  padding: 10px;
}

.van-cell {
  padding: 0;
}

.img-box {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #dbeaf9;
  border-radius: 50%;
}

.img-box img {
  width: 58%;
  text-align: center;
  vertical-align: middle;
}
:deep .van-tag--plain{
  padding: 2px 4px;
  text-align: center;
  vertical-align: middle;
}
</style>
