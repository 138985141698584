<template>
  <div class="messages">
    <!-- 如果按照天来循环： -->
    <div>
      <van-divider>时间</van-divider>
      <div class="msgtime">
        <div class="box-card">
          <div class="clearfix">
            <p class="msgTitle">
              <span>
                <van-icon name="envelop-o" color="#1989fa" /> 访客通知
              </span>
            </p>
          </div>
          <div class="box-body">
            <h4 class="fs-20">访问预约取消</h4>
            <p class="grayTColor">10月21日 23:14</p>
            <p>取消的原因：有其他事情</p>
          </div>
          <div class="box-bottom">
            <van-cell title="查看详情" is-link />
          </div>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <p class="msgTitle">
              <span>
                <van-icon name="envelop-o" color="#1989fa" /> 访客通知
              </span>
            </p>
          </div>
          <div class="box-body">
            <h4 class="fs-20">访问预约取消</h4>
            <p class="grayTColor">10月21日 23:14</p>
            <p>取消的原因：有其他事情</p>
          </div>
          <div class="box-bottom">
            <van-cell title="查看详情" is-link />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "messages",
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  activated: function () {
  },
  deactivated: function () {
  },
  updated() { },
  methods: {

  }
}
</script>

<style scoped>
/* 消息 */
.msgtime {
  margin: 10px auto;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.box-card {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgb(179, 216, 252);
  background: #ffffff;
}

.msgTitle {
  text-align: left;
  border-bottom: 1px solid #EBEEF5;
}

.box-body {
  text-align: left;
  padding: 10px;
}

.box-bottom {
}

.van-cell__title,
.van-cell__value {
  text-align: left;
}
</style>
