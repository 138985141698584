// 云商
import request from '../util/request';
// （云商详情）企业供应信息详情 id=1582980016246251522
export function get_cloud_supply(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpCompSupply/queryById',
    params: data,
  })
}
//（云商详情）企业需求信息id=1582983055942246402
export function get_cloud_demand(data) {
  return request({
    method: 'GET',
    url: '/dmp/dmpCompDemand/queryById',
    params: data,
  })
}