<!-- 了解园区 -->
<template>
  <div class="parkDetails">
    <div class="park-title-box">
      <h3 class="fs-25">{{ title }}</h3>
      <p>{{ tip }}<span class="grayTColor ml-10">{{ tipTime }}</span></p>
    </div>
    <div class="park-main">
      <img src="../../assets/img/user.png" alt="内容">
    </div>
  </div>
</template>

<script>
export default {
  name: "ParkDetails",
  components: {},
  data() {
    return {
      title: '商务，为城市带来全新想象',
      tip: 'coco中海商务',
      tipTime: '2018-09-26',
      mainSrc: '../assets/img/user.png'
    };
  },
  computed: {
  },
  created() {
  },
  activated: function () {
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
  }
}
</script>

<style scoped>
/* 了解园区 */
.parkDetails {
  background: #ffffff;
  padding: 10px;
}
.park-title-box {
  text-align: left;
}

.park-main {
}
.park-main img {
  width: 100%;
}
</style>
