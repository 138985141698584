<template>
  <div class="InviteChoose">
    <div class="invite-page-content">
      <h3 class="fs-25 invite-title">选择访客邀请方式</h3>
      <!-- 访客填写 -->
      <div class="sendBox" @click="toInvite">
        <div class="flex-nowrap">
          <div class="sendBox-left">
            <span class="successBg"><img src="@/assets/img/fenxiang.png" alt="fenxiang"></span>
          </div>
          <div class="sendBox-right">
            <h4 class="fs-20">发送表单给访客</h4>
            <p class="grayTColor">将邀请函分享给访客，由访客自主填写访问申请</p>
          </div>
        </div>
        <div class="flex-nowrap send-box-process">
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/biaodan.png" alt="biaodan">
            <p>发送访客表单</p>
          </div>
          <van-divider dashed content-position="left"
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }">
          </van-divider>
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/tianxie.png" alt="tianxie">
            <p>访客填写信息</p>
          </div>
          <van-divider dashed content-position="left"
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }">
          </van-divider>
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/dengdai.png" alt="dengdai">
            <p>等待审核</p>
          </div>
        </div>
      </div>
      <!-- 我填写 -->
      <div class="sendBox" @click="toInviteForm">
        <div class="flex-nowrap">
          <div class="sendBox-left">
            <span class="warningBg"><img src="@/assets/img/dakaixinxi.png" alt="dakaixinxi"></span>
          </div>
          <div class="sendBox-right">
            <h4 class="fs-20">我来填写邀请访客信息</h4>
            <p class="grayTColor">将邀请函分享给访客，由访客自主填写访问申请</p>
          </div>
        </div>
        <div class="flex-nowrap send-box-process">
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/bianjiziliao.png" alt="bianjiziliao">
            <p>填写访客信息</p>
          </div>
          <van-divider dashed content-position="left"
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }">
          </van-divider>
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/fasong.png" alt="fasong">
            <p>发送访客邀请</p>
          </div>
          <van-divider dashed content-position="left"
            :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }">
          </van-divider>
          <div class="send-img-box">
            <img class="sendImg" src="@/assets/img/wancheng.png" alt="wancheng">
            <p>完成邀请</p>
          </div>
        </div>
      </div>
      <!-- 唤起分享： -->
      <!-- <van-cell title="显示分享面板" @click="showShare = true"></van-cell> -->
      <van-share-sheet v-model="showShare" title="立即分享给好友" :options="options" @select="onSelect"></van-share-sheet>
    </div>
  </div>
</template>

<script>
// import { getProblemList } from '@/api/invite'
import Cookies from 'js-cookie'
export default {
  name: "InviteChoose",
  components: {},
  data() {
    return {
      showShare: false,
      options: [
        { name: '微信', icon: 'wechat' },
      ],
    };
  },
  computed: {
  },
  created() {
  },
  activated: function () {
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    // 访客自己填写:
    toInvite(){
      this.showShare = true
    },
    onClickLeft() {
      this.$router.push({
        path:`/invite`
      })
    },
    // 选择分享
    onSelect(option) {
      const parkId = Cookies.get('parkId');// 默认值
      const inviterMobile = Cookies.get('repairMobile');
      if (option.name === '微信') {
        let databody  = JSON.stringify({"h5":"http://w.dmp.ydcz.cn/index.html#/InviteChoose","wx":"/pages/invite/invite?parkId="+parkId+"&inviterMobile="+inviterMobile })
        alert(`[share]${databody}`)
      } else {
        window.location.href = 'sms://' + option.name
      }
      this.showShare = false;
    },
    // 去填写邀请表
    toInviteForm(){
      this.$router.push({
        path:`/inviteVisitors`
      })
    },
  }
}
</script>

<style scoped>
/* 我来填写邀请访客信息： */
.invite-page-content {
    padding: 10px;
    height: 100vh;
    background: #ffffff;
}
.sendBox {
    margin: 15px 10px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 10px rgb(179, 216, 252);
}
.flex-nowrap {
    display: flex;
    flex: nowrap;
}
.sendBox-left {
    /* width: 10%; */
}
.sendBox-left span {
    margin: 10px;
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50%;
}
.sendBox-left span img {
    display: inline-block;
    width: 20px;
    margin: 5px;
}
.sendBox-right {
    text-align: left;
}
.send-box-process {
    text-align: center;
    margin-top: 15px;
}
.send-img-box {
    text-align: center;
}
.sendImg {
    width: 40px;
    margin: auto;
}
</style>
