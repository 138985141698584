<template>
  <div class="Problems">
    <div class="problemList">
      <van-cell v-for="item in problemList" @click="toDetail(item.id)" :title="item.title" is-link :key="item.title">
      </van-cell>
    </div>
  </div>
</template>

<script>
import { getProblemList } from '@/api/problems'
export default {
  name: "Problems",
  components: {},
  data() {
    return {
      problemList: []
    };
  },
  computed: {
  },
  created() {
    getProblemList({ pageNo: 1, pageSize: 10 }).then(res => {
      this.problemList = res.data.result.records
    })
  },
  activated: function () {
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: `/problemDetails`,
        query: {
          problemId: id
        }
      })
    }
  }
}
</script>

<style scoped>
.Problems {
  background-color: #fff;
  height: 100vh;
}
.problemList {
  text-align: left;
}
</style>
