<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <button @click="tochooseinvite()">取选择邀请访客</button> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  data(){
    return {}
  },
  methods: {
    tochooseinvite() {
      this.$router.push({
        path: `/inviteChoose`,
      });
    },
  }
}
</script>
