<!-- 维修详情： -->
<template>
  <div class="maintenanceDetails">
    <div class="category-box">
      <van-form>
        <div class="categoryCen">
          <!-- 报修内容：： -->
          <p class="fs-16 fw">
            <van-icon name="coupon-o" />
            报修内容：
          </p>
          <div class="flex">
            <div class="flex-1">
              <p class="fs-20"><span>{{ repairDetail.repairContent }}</span></p>
              <p>报修楼幛/公司：<span>{{ repairDetail.repairBuildNo }}</span></p>
              <p>报修人：<span>{{ repairDetail.createBy }}</span></p>
              <a href="'tel://'+ repairDetail.repairMobile">报修人电话：<span>{{ repairDetail.repairMobile }}</span></a>
            </div>
            <div class="flex-shrink-0 w-80" @click="showFun(repairDetail.repairPic)" v-if="repairDetail.repairPic.length">
              <img class="w-full"
                :src="'http://api.dmp.ydcz.cn/dmp-boot/sys/common/static/' + repairDetail.repairPic[0]" alt="暂无图片" />
            </div>
          </div>

          <van-divider />
          <!-- 维修记录： -->
          <p class="fs-16 fw">
            <van-icon name="clock-o" />
            维修记录：
          </p>
          <p v-if="!maintenanceList.length">暂无记录</p>
          <div v-for="i in maintenanceList" :key="i.id" class="box-body flex">
            <div class="flex-1">
              <p class="grayTColor">{{ i.createTime }}</p>
              <p>{{ i.repairResultContent }}</p>
              <p>处理人：{{ i.createBy }}</p>
            </div>
            <div class="flex-shrink-0 w-80" @click="showFun(i.repairResultPic)">
              <img class="w-full" :src="'http://api.dmp.ydcz.cn/dmp-boot/sys/common/static/' + i.repairResultPic[0]" />
            </div>
          </div>
          <van-divider />

          <!-- 新的维修记录： -->
          <p class="fs-16 fw">
            <van-icon name="todo-list-o" />新的维修记录：
          </p>
          <div>
            <van-field label-width="90" @focus="noBomBox" clickable name="picker" :value="list.repairType_dictText" label="维修状态：" placeholder="请选择维修状态"
              @click="showPicker = true" />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker show-toolbar value-key="value" :columns="projectList" @confirm="onState"
                @cancel="showPicker = false" />
            </van-popup>
            <!-- <br /> -->
            <van-field label-width="90" class="repairContent" label="维修情况：" v-model="list.repairContent" rows="4" autosize type="textarea"
              maxlength="150" placeholder="请填写维修情况" show-word-limit />
            <br />
            <van-uploader v-model="fileList" :after-read="afterRead"></van-uploader>
          </div>
          <div>
            <p>联系电话</p>
            <h4 class="fs-16 fw_600">{{ list.repairMobile }}</h4>
          </div>
        </div>
        <div class="toOption">
          <van-button @click="onSubmit()" size="large">提交新的维修记录</van-button>
        </div>
      </van-form>
    </div>
    <!-- 弹窗 -->
    <van-dialog v-model="showImg" title="查看图片" :showCancelButton="false">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img class="dialogimg" :src="'http://api.dmp.ydcz.cn/dmp-boot/sys/common/static/' + image" />
        </van-swipe-item>
      </van-swipe>
    </van-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import baseURL from '../../util/config';
import { add_service, get_repairtype_list, get_maintenance_list, get_repair_detail } from "@/api/repair"
export default {
  name: "maintenanceDetails",
  components: {},
  data() {
    return {
      // 维修记录列表：
      maintenanceList: [],
      // 追加的需要传回的历史维修记录：
      oldMaintenanceList: [],
      // 维修状态选择框：
      showPicker: false,
      // 报修详情
      repairDetail: {
        repairContent: '',
        repairMobile: '',
        repairPic: '',
      },
      // 维修状态类别
      projectList: [],
      list: {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: '',
        repairType: '',
      },
      //上传
      fileList: [],
      imgList: [],
      pageid: '', // 维修id
      showImg: false, // 图片弹窗
      images: [],
    };
  },
  computed: {
  },
  created() {
    this.getPhoneNum()
  },
  activated: function () {
    this.getPhoneNum()
    this.getMaintenanceList();
    this.getRepairtype();
    this.pageid = this.$route.query.pageid;
    this.repairsDetail();
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    getPhoneNum() {
      this.list.repairMobile = Cookies.get('repairMobile')
    },
    // 获取维修处理情况列表
    getMaintenanceList() {
      let pageid = this.$route.query.pageid
      get_maintenance_list({ id: pageid }).then((res) => {
        this.oldMaintenanceList = res.data.result // 追加存储维修详情记录给传回用的
        this.maintenanceList = res.data.result
        this.maintenanceList = res.data.result.map((e) => ({
          ...e,
          repairResultPic: e.repairResultPic.split(","),
        }));
      })
    },
    // 获取维修状态
    getRepairtype() {
      get_repairtype_list({ dictId: '1581175272477499393' }).then((res) => {
        const projectList = res.data.result?.records.map((e) => ({
          value: e.itemText,
          label: e.itemValue,
        }));
        this.projectList = projectList
      })
    },
    // // 获取报修详情
    repairsDetail() {
      let pageid = this.$route.query.pageid
      if (pageid) {
        get_repair_detail({ id: pageid }).then((res) => {
          this.repairDetail = res.data.result
          res.data.result.repairPic ? this.repairDetail.repairPic = res.data.result?.repairPic?.split(",") : this.repairDetail.repairPic = ''
        })
      } else {
        // this.clear()
      }
    },
    clear() {
      this.list = {
        repairType_dictText: '',
        repairContent: '',
        repairMobile: this.list.repairMobile,
        repairType: '',
      }
      this.fileList = []
      this.imgurl = '',
      this.imgList = [],
      this.oldMaintenanceList = []
    },
    // // 点击返回
    onClickLeft() {
      this.$router.push({
        path: `/maintenance`
      })
    },
    // 上传：
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData()
      formData.append('file', file.file)
      axios({
        method: 'post',
        headers: {
          'X-Access-Token': Cookies.get('X-Access-Token')
        },
        url: `${baseURL}sys/common/upload`,
        data: formData
      }).then((res) => {
        if (res.data.success == true) {
          // 上传状态提示关闭
          file.status = "done";
          this.$toast("上传成功！");
          this.imgList.push(res.data.message)
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    // 上传
    beforeRead(file) {
      const type = ['image/jpeg', 'image/png']
      const isImage = type.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isImage) {
        this.$toast('上传图片格式不正确')
      }
      if (!isLt2M) {
        this.$toast('图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    // 上传结束
    // 提交
    onSubmit() {
      const repairData = {
        id: this.pageid,
        repairState: this.list.repairType,
        dmpRepairsRecList: [
          ...this.oldMaintenanceList,
          {
          repairId: this.pageid,
          repairResultContent: this.list.repairContent,
          repairResultPic: this.imgList.join(','),
        }]
      }
      // 新增维修记录（根据后端要求把前面的记录也添加上传回去）
      add_service(JSON.parse(JSON.stringify(repairData))).then((res) => {
        if (res.data.code === 200) {
          this.$toast('提交成功')
          this.onClickLeft();
          this.clear();
        }
      }).catch((err) => {
        this.$toast(err)
      })
    },
    // 点击显示图片弹窗
    showFun(list) {
      this.showImg = true;
      this.images = list
    },
    // 选择维修状态
    onState(val) {
       this.$set(this.list, 'repairType_dictText', val.value)
      this.$set(this.list, 'repairType', val.label)
      this.showPicker = false;
    },
    // 禁止调起键盘
    noBomBox() {
      document.activeElement.blur();
    },
  }
}
</script>

<style scoped>
.repairDetails {
  background: #ffffff;
  height: 100vh;
}

.category-box {
  padding: 10px;
  padding-bottom: 45px;
  text-align: left;
  background-color: #ffffff;
}
.categoryCen {
  padding-bottom: 55px;
}
.categoryCen p, .categoryCen a {
  font-size: 14px;
}
.categoryCen p span, .categoryCen a span {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}
.musticon {
  /* text-align: left; */
}

.repairContent {
  padding: 0;
}

.dialogimg {
  width: 80%;
  text-align: center;
}

:deep .van-tab span {
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  padding: 3px;
}

:deep .van-tab--active span {
  border-radius: 5px;
  border: 1px solid #409EFF;
}

:deep .van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 1;
  height: 44px;
  padding: 0 10px;
  background-color: #dddddd47;
  border-radius: 30px;
  box-sizing: content-box;
}

:deep .van-tab--active {
  font-weight: bold;
}
:deep .van-cell {
  padding: 10px 0;
}
.toOption {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.toOption button {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  background-color: #5399FF;
  color: #ffffff;
}

.phoneinput {
  padding: 0 !important
}
:deep(.van-field__control) {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
::v-deep .van-field__control{
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

::v-deep .van-field__label {
  text-align: right;
  /* text-align-last:justify; */
}
</style>
