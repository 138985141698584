import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
Vue.prototype.axios = axios

Vue.use(Vant);

import { Toast } from 'vant';
Vue.use(Toast);


// import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor, {placeholder: '请填写内容',});


import "./util/px2rem"

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  VueQuillEditor,
  render: (h) => h(App),
}).$mount("#app");

