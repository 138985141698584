//时间戳格式化为yyyy-MM-dd hh:mm:ss格式，其他格式也可自行更改
// export const formatDateTime = (val) => {
//   var date = new Date(val);
//   var timeStr = date.getFullYear() + '-';
//   if (date.getMonth() < 9) {
//     //月份从0开始的
//     timeStr += '0';
//   }
//   timeStr += date.getMonth() + 1 + '-';
//   timeStr += date.getDate() < 10 ? ('0' + date.getHours()) : date.getHours();
//   timeStr += ' ';
//   timeStr += date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
//   timeStr += ':';
//   timeStr += date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
//   timeStr += ':';
//   timeStr += date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds();
//   return timeStr;
// }

//格式化时间
export const format = (dat) => {
  //获取年月日，时间
  var year = dat.getFullYear();
  var mon = (dat.getMonth() + 1) < 10 ? "0" + (dat.getMonth() + 1) : dat.getMonth() + 1;
  var data = dat.getDate() < 10 ? "0" + (dat.getDate()) : dat.getDate();
  var hour = dat.getHours() < 10 ? "0" + (dat.getHours()) : dat.getHours();
  var min = dat.getMinutes() < 10 ? "0" + (dat.getMinutes()) : dat.getMinutes();
  var seon = dat.getSeconds() < 10 ? "0" + (dat.getSeconds()) : dat.getSeconds();

  var newDate = year + "-" + mon + "-" + data + " " + hour + ":" + min + ":" + seon;
  return newDate;
}

//获取最近7天日期

// getDay(0);//当天日期

// getDay(-7);//7天前日期

// //获取最近3天日期

// getDay(0);//当天日期

// getDay(-3);//3天前日期

export function getDay(day) {

  var today = new Date();

  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

  today.setTime(targetday_milliseconds); //注意，这行是关键代码

  var tYear = today.getFullYear();

  var tMonth = today.getMonth();

  var tDate = today.getDate();

  tMonth = doHandleMonth(tMonth + 1);

  tDate = doHandleMonth(tDate);

  return tYear + "-" + tMonth + "-" + tDate + " "+"00:00:00";

}

function doHandleMonth(month) {

  var m = month;

  if (month.toString().length == 1) {

    m = "0" + month;

  }

  return m;

}
// 获取本周
export function getMonday(type, dates) {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var longTime = 24 * 60 * 60 * 1000;
  var n = longTime * 7 * (dates || 0);
  var dd = '';
  if (type === "s") {
    dd= nowTime - (day - 1) * longTime + n;
  }
  if (type === "e") {
    dd = nowTime + (7 - day) * longTime + n;
  }
  dd = new Date(dd);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  var d = dd.getDate();
  m = m < 10 ? "0" + m: m;
  d = d < 10 ? "0" + d: d;
  var dayweek = y + "-" + m + "-" + d + ' 00:00:00';
  return dayweek;
}

// getMonday(type,dates)  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
// getMonday("s",1)  //得到下周一的yyyy-mm-dd格式日期
// getMonday("e",1)  //得到下周日的yyyy-mm-dd格式日期