<template>
  <div class="problemDetails">
    <div class="problem-detail">
      <div v-html="contentHtml"></div>
    </div>
  </div>
</template>

<script>
import { get_problem_details } from '@/api/problems'
export default {
  name: "ProblemDetails",
  components: {},
  data() {
    return {
      problemTitle: '',
      contentHtml: '',
      pageid: '',
    };
  },
  computed: {
  },
  created() {
    this.pageid = this.$route.query.problemId
  },
  mounted() {
    this.$nextTick(() => {
      this.getDetails()
    })
  },
  deactivated: function () {
  },
  updated() { },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: `/problems`
      })
    },
    getDetails() {
      if (this.pageid) {
        get_problem_details({ id: this.pageid }).then(res => {
          this.contentHtml = res.data.result.content
        })
      }
    },
  }
}
</script>

<style scoped>
.problemDetails {
  background-color: #fff;
  height: 100vh;
}

.problem-detail {
  background: #fff;
  text-align: left;
  padding: 10px;
}
</style>
