var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"InviteChoose"},[_c('div',{staticClass:"invite-page-content"},[_c('h3',{staticClass:"fs-25 invite-title"},[_vm._v("选择访客邀请方式")]),_c('div',{staticClass:"sendBox",on:{"click":_vm.toInvite}},[_vm._m(0),_c('div',{staticClass:"flex-nowrap send-box-process"},[_vm._m(1),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }),attrs:{"dashed":"","content-position":"left"}}),_vm._m(2),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }),attrs:{"dashed":"","content-position":"left"}}),_vm._m(3)],1)]),_c('div',{staticClass:"sendBox",on:{"click":_vm.toInviteForm}},[_vm._m(4),_c('div',{staticClass:"flex-nowrap send-box-process"},[_vm._m(5),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }),attrs:{"dashed":"","content-position":"left"}}),_vm._m(6),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px', width: '30px' }),attrs:{"dashed":"","content-position":"left"}}),_vm._m(7)],1)]),_c('van-share-sheet',{attrs:{"title":"立即分享给好友","options":_vm.options},on:{"select":_vm.onSelect},model:{value:(_vm.showShare),callback:function ($$v) {_vm.showShare=$$v},expression:"showShare"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-nowrap"},[_c('div',{staticClass:"sendBox-left"},[_c('span',{staticClass:"successBg"},[_c('img',{attrs:{"src":require("@/assets/img/fenxiang.png"),"alt":"fenxiang"}})])]),_c('div',{staticClass:"sendBox-right"},[_c('h4',{staticClass:"fs-20"},[_vm._v("发送表单给访客")]),_c('p',{staticClass:"grayTColor"},[_vm._v("将邀请函分享给访客，由访客自主填写访问申请")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/biaodan.png"),"alt":"biaodan"}}),_c('p',[_vm._v("发送访客表单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/tianxie.png"),"alt":"tianxie"}}),_c('p',[_vm._v("访客填写信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/dengdai.png"),"alt":"dengdai"}}),_c('p',[_vm._v("等待审核")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-nowrap"},[_c('div',{staticClass:"sendBox-left"},[_c('span',{staticClass:"warningBg"},[_c('img',{attrs:{"src":require("@/assets/img/dakaixinxi.png"),"alt":"dakaixinxi"}})])]),_c('div',{staticClass:"sendBox-right"},[_c('h4',{staticClass:"fs-20"},[_vm._v("我来填写邀请访客信息")]),_c('p',{staticClass:"grayTColor"},[_vm._v("将邀请函分享给访客，由访客自主填写访问申请")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/bianjiziliao.png"),"alt":"bianjiziliao"}}),_c('p',[_vm._v("填写访客信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/fasong.png"),"alt":"fasong"}}),_c('p',[_vm._v("发送访客邀请")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"send-img-box"},[_c('img',{staticClass:"sendImg",attrs:{"src":require("@/assets/img/wancheng.png"),"alt":"wancheng"}}),_c('p',[_vm._v("完成邀请")])])
}]

export { render, staticRenderFns }