import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Invite from "../views/invite/Invite.vue";
import Problems from '../views/problems/Problems';
import ProblemDetails from "../views/problems/ProblemDetails";
import InviteDetail from "../views/invite/InviteDetail";
import InviteChoose from "../views/invite/InviteChoose";
import Messages from "../views/messages/Messages"
import InviteVisitors from "../views/invite/InviteVisitors";
import ParkDetails from "../views/park/parkDetails";
import Repair from "../views/repair/repair";
import RepairDetails from "../views/repair/repairDetails";
import Complaint from "../views/complaint/complaint";
import ComplaintDetails from "../views/complaint/complaintDetails";
import CloudDetail from "../views/cloud/cloudDetail";
import Maintenance from "../views/maintenance/maintenance";
import MaintenanceDetails from "../views/maintenance/maintenanceDetails";
import MeetingRoom from "../views/meetingRoom/meetingRoom";
import MeetingDetail from "../views/meetingRoom/meetingDetail";
import EditArticle from "../views/editArticle/editArticle";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "about",
      show: true,
    }
  },
  {
    path: "/invite",
    name: "invite",
    component: Invite,
    meta: {
      title: "邀请",
      show: true,
    }
  },
  {
    path: "/inviteChoose",
    name: "inviteChoose",
    component: InviteChoose,
    meta: {
      title: "邀请方式",
      show: true,
    }
  },
  {
    path: "/inviteDetail",
    name: "inviteDetail",
    component: InviteDetail,
    meta: {
      title: "邀请详情",
      show: true,
    }
  },
  {
    path: "/Problems",
    name: "Problems",
    component: Problems,
    meta: {
      title: "常见问题",
      show: true,
    }
  },
  {
    path: '/problemDetails',
    name: 'problemDetails',
    component: ProblemDetails,
    meta: {
      title: "问题详情",
      show: true,
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      title: "访客详情",
      show: true,
    }
  },
  {
    path: '/inviteVisitors',
    name: 'inviteVisitors',
    component: InviteVisitors,
    meta: {
      title: "访客邀请",
      show: true,
    }
  },
  {
    path: '/parkDetails',
    name: 'parkDetails',
    component: ParkDetails,
    meta: {
      title: "园区详情",
      show: true,
    }
  },
  {
    path: '/repair',
    name: 'repair',
    component: Repair,
    meta: {
      title: "报修",
      show: true,
    }
  },
  {
    path: '/repairDetails',
    name: 'repairDetails',
    component: RepairDetails,
    meta: {
      title: "报修详情",
      show: true,
    }
  },
  {
    path: '/complaint',
    name: 'complaint',
    component: Complaint,
    meta: {
      title: "投诉",
      show: true,
    }
  },
  {
    path: '/complaintDetails',
    name: 'complaintDetails',
    component: ComplaintDetails,
    meta: {
      title: "投诉详情",
      show: true,
    }
  },
  {
    path: '/cloudDetail',
    name: 'cloudDetail',
    component: CloudDetail,
    meta: {
      title: "云商详情",
      show: true,
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      title: "维修",
      show: true,
    }
  },
  {
    path: '/maintenanceDetails',
    name: 'maintenanceDetails',
    component: MaintenanceDetails,
    meta: {
      title: "维修详情",
      show: true,
    }
  },
  {
    path: '/meetingRoom',
    name: 'meetingRoom',
    component: MeetingRoom,
    meta: {
      title: "预定会议室",
      show: true,
    }
  },
  {
    path: '/meetingDetail',
    name: 'meetingDetail',
    component: MeetingDetail,
    meta: {
      title: "会议室详情",
      show: true,
    }
  },
  {
    path: '/editArticle',
    name: 'editArticle',
    component: EditArticle,
    meta: {
      title: "我的需求",
      show: true,
    }
  }
];


const router = new VueRouter({
  routes,
  mode: 'hash' //  有#
  // mode: 'history' // 空白
});

export default router;
